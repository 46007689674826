// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/chabanenkopavlo/isse-landing-page/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authors-js": () => import("/Users/chabanenkopavlo/isse-landing-page/src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-committees-js": () => import("/Users/chabanenkopavlo/isse-landing-page/src/pages/committees.js" /* webpackChunkName: "component---src-pages-committees-js" */),
  "component---src-pages-history-js": () => import("/Users/chabanenkopavlo/isse-landing-page/src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("/Users/chabanenkopavlo/isse-landing-page/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-overview-js": () => import("/Users/chabanenkopavlo/isse-landing-page/src/pages/overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-results-js": () => import("/Users/chabanenkopavlo/isse-landing-page/src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-sponsors-js": () => import("/Users/chabanenkopavlo/isse-landing-page/src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */)
}

